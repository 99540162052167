<template>
  <div class="container-fluid">
    <div class="card border-0 shadow-none">
      <div class="card-body">
        <div class="row">
          <div class="col-12 col-md mb-4">
            <h3 class="fw-bold text-success mb-0">Retreat Bookings</h3>
          </div>
          <div class="col-2 mb-4">
            <select
              v-model="retreatFilter"
              class="form-control me-2"
            >
              <option value="">All retreats</option>
              <option value="uk">UK</option>
              <option value="malta">Malta</option>
            </select>
          </div>
          <div class="col-2 mb-4">
            <select
              v-model="statusFilter"
              class="form-control me-2"
            >
              <option value="">All statuses</option>
              <option value="paid">Paid</option>
              <option value="not_paid">Not Paid</option>
              <option value="partial_payment">Partial Payment</option>
            </select>
          </div>
          <div class="col-auto mb-4 ms-auto">
            <date-picker
              ref="datePicker"
              v-model="range"
              is-range
              :popover="{ visibility: 'click', transition: 'fade' }"
              :input-props="{ class: 'form-control form-control-sm' }"
            >
              <template v-slot="{ inputValue, togglePopover }">
                <div class="date-picker-wrap">
                  <input
                    :value="inputValue.start + ' - ' + inputValue.end"
                    v-on:click="togglePopover"
                    class="d-inline form-control disabled"
                    placeholder="End Date"
                    readonly
                  />
                </div> </template
            ></date-picker>
          </div>
        </div>

        <div class="row">
          <div class="col-md-3 mb-4">
            <div class="card border-0 bg-light shadow-none">
              <div class="card-body text-center">
                <h6 class="text-success mb-0 fw-bold">Total Bookings</h6>
                <hr class="my-2" />
                <h4 class="text-success mb-0 fw-bold">
                  {{ bookings.length }}
                </h4>
              </div>
            </div>
          </div>

          <div class="col-md-3 mb-4">
            <div class="card border-0 bg-light shadow-none">
              <div class="card-body text-center">
                <h6 class="text-success mb-0 fw-bold">Total Turnover</h6>
                <hr class="my-2" />
                <h4 class="text-success mb-0 fw-bold">
                  £{{ totalTurnover.toFixed(2) | numberWithCommas }}
                </h4>
              </div>
            </div>
          </div>
          <div class="col-md-3 mb-4">
            <div class="card border-0 bg-light shadow-none">
              <div class="card-body text-center">
                <h6 class="text-success mb-0 fw-bold">Total Paid</h6>
                <hr class="my-2" />
                <h4 class="text-success mb-0 fw-bold">
                  £{{ totalPaid.toFixed(2) | numberWithCommas }}
                </h4>
              </div>
            </div>
          </div>
          <div class="col-md-3 mb-4">
            <div class="card border-0 bg-light shadow-none">
              <div class="card-body text-center">
                <h6 class="text-success mb-0 fw-bold">Total Outstanding</h6>
                <hr class="my-2" />
                <h4 class="text-success mb-0 fw-bold">
                  £{{ totalOutstanding.toFixed(2) | numberWithCommas }}
                </h4>
              </div>
            </div>
          </div>
        </div>

        <div class="table-responsive">
          <table class="table" v-if="!busy">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Clients</th>
                <th scope="col">Retreat</th>
                <th
                  scope="col flex align-items-center"
                  @click="sortByArrival()"
                >
                  Arrival
                  <i
                    class="fa fa-sort ms-2"
                    :class="arrivalSort == ''
                      ? 'fa-sort'
                      : (arrivalSort == 'asc' ? 'fa-sort-up' : 'fa-sort-down')"
                  ></i>
                </th>
                <th scope="col">Total</th>
                <th scope="col">Paid</th>
                <th scope="col">Status</th>
                <th scope="col">Date</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="b in bookings" :key="b.id">
                <th
                  @click="goToBooking(b.clients[0].id, b.booking.id)"
                  scope="row"
                >
                  {{ b.id }}
                </th>
                <td @click="goToBooking(b.clients[0].id, b.booking.id)">
                  <span v-for="c in b.clients" :key="c.id">{{ c.name }} </span>
                </td>
                <td @click="goToBooking(b.clients[0].id, b.booking.id)">
                  {{ b.booking.retreat }}
                </td>
                <td @click="goToBooking(b.clients[0].id, b.booking.id)">
                  {{ b.arrival_date | formatDate }}
                </td>
                <td @click="goToBooking(b.clients[0].id, b.booking.id)">
                  £{{ b.total_cost.toFixed(2) }}
                </td>
                <td @click="goToBooking(b.clients[0].id, b.booking.id)">
                  £{{ b.total_paid.toFixed(2) }}
                </td>
                <td
                  @click="goToBooking(b.clients[0].id, b.booking.id)"
                  class="text-capitalize"
                >
                  {{ b.payment_status | formatStatus }}
                </td>
                <td @click="goToBooking(b.clients[0].id, b.booking.id)">
                  {{ b.created_at | formatDate }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <loading v-if="busy"></loading>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from "../../components/loaders/Loading";
import DatePicker from "v-calendar/lib/components/date-picker.umd";

export default {
  data() {
    return {
      arrivalSort: "",
      busy: false,
      bookings: [],
      popover: {
        visibility: "focus"
      },
      range: {
        start: this.$route.query.start ? this.$route.query.start : new Date(),
        end: this.$route.query.end ? this.$route.query.end : new Date()
      },
      retreatFilter: "",
      statusFilter: "",
      // range: {
      //   start: moment(this.$route.params.start)
      //     ? moment(this.$route.params.start).format("YYYY-MM-DD")
      //     : null,
      //   end: moment(this.$route.params.end)
      //     ? moment(this.$route.params.end).format("YYYY-MM-DD")
      //     : null
      // },
      // datePickerOptions: {
      //   minDate: null,
      //   maxDate: null,
      //   singleDatePicker: false,
      //   timePicker: false,
      //   timePicker24Hour: false,
      //   showWeekNumbers: false,
      //   showDropdowns: true,
      //   autoApply: true,
      //   linkedCalendars: true
      // }
    };
  },
  computed: {
    totalTurnover() {
      return this.bookings.reduce((a, b) => {
        return a + parseInt(b.total_cost);
      }, 0);
    },

    totalPaid() {
      return this.bookings.reduce((a, b) => {
        return a + parseInt(b.total_paid);
      }, 0);
    },

    totalOutstanding() {
      return this.bookings.reduce((a, b) => {
        return a + parseInt(b.total_outstanding);
      }, 0);
    }
  },
  watch: {
    "range.start": {
      handler: function(val, old) {
        if (val != old) {
          this.$router
            .replace({
              path: this.$route.currentPath,
              query: {
                arrival: this.arrivalSort,
                type: this.typeFilter,
                status: this.statusFilter,
                start: moment(this.range.start).format("YYYY-MM-DD"),
                end: moment(this.range.end).format("YYYY-MM-DD"),
                retreat: this.retreatFilter
              }
            })
            .catch(() => {});
          this.fetchRetreatBookings();
        }
      }
    },

    "range.end": {
      handler: function(val, old) {
        if (val != old) {
          this.$router
            .replace({
              path: this.$route.currentPath,
              query: {
                arrival: this.arrivalSort,
                type: this.typeFilter,
                status: this.statusFilter,
                start: moment(this.range.start).format("YYYY-MM-DD"),
                end: moment(this.range.end).format("YYYY-MM-DD"),
                retreat: this.retreatFilter
              }
            })
            .catch(() => {});
          this.fetchRetreatBookings();
        }
      }
    },

    "retreatFilter": {
      handler: function(val, old) {
        if (val != old) {
          this.$router
            .replace({
              path: this.$route.currentPath,
              query: {
                arrival: this.arrivalSort,
                type: this.typeFilter,
                status: this.statusFilter,
                start: moment(this.range.start).format("YYYY-MM-DD"),
                end: moment(this.range.end).format("YYYY-MM-DD"),
                retreat: this.retreatFilter
              }
            })
            .catch(() => {});
          this.fetchRetreatBookings();
        }
      }
    },

    "statusFilter": {
      handler: function(val, old) {
        if (val != old) {
          this.$router
            .replace({
              path: this.$route.currentPath,
              query: {
                arrival: this.arrivalSort,
                type: this.typeFilter,
                status: this.statusFilter,
                start: moment(this.range.start).format("YYYY-MM-DD"),
                end: moment(this.range.end).format("YYYY-MM-DD"),
                retreat: this.retreatFilter
              }
            })
            .catch(() => {});
          this.fetchRetreatBookings();
        }
      }
    }
  },
  methods: {
    open() {
      this.$refs["datePicker"].$el.querySelector("input").focus();
    },
    goToBooking(client, booking) {
      this.$router.push({
        path: "/clients/" + client,
        query: {
          "retreat-booking": booking
        },
        hash: "retreat-bookings"
      });
    },
    fetchRetreatBookings() {
      this.bookings = [];
      this.busy = true;
      this.$axios(process.env.VUE_APP_API_URL + this.$route.fullPath)
        .then(({ data }) => {
          this.bookings = data;
        })
        .finally(() => {
          this.busy = false;
        });
    },
    updateValues(range) {
      this.fetchRetreatBookings();
    },
    sortByArrival() {
      switch (this.arrivalSort) {
        case "asc":
          this.arrivalSort = "";
          break;
        case "desc":
          this.arrivalSort = "asc";
          break;
        default:
          this.arrivalSort = "desc";
          break;
      }
      this.$router
        .replace({
          path: this.$route.currentPath,
          query: {
            arrival: this.arrivalSort,
            type: this.typeFilter,
            status: this.statusFilter,
            start: moment(this.range.start).format("YYYY-MM-DD"),
            end: moment(this.range.end).format("YYYY-MM-DD"),
            retreat: this.retreatFilter
          }
        })
        .catch(() => {});
      this.fetchRetreatBookings();
    }
  },
  mounted() {
    this.$store.sidebarOpen = false;
    this.fetchRetreatBookings();
  },
  filters: {
    numberWithCommas(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    formatDate(date) {
      if (date) {
        return moment(date).format("LL");
      }
      return "Not Set";
    },
    formatCallBackDate(date) {
      if (date) {
        return moment.utc(date).format("LLL");
      }
      return "Not Set";
    },
    formatType(type) {
      return type.replace(/_/g, " ");
    },
    formatStatus(status) {
      return status.replace(/_/g, " ");
    }
  },
  components: {
    Loading,
    DatePicker
  }
};
</script>

<style>
</style>